<template>
    <v-container>
        <v-card flat class="pb-2">
            <v-card-title primary-title class="letter-spacing">
                {{details.name}}
                <v-spacer></v-spacer>
                <v-btn color="primary" elevation="0" small outlined @click="creating = true" class="ml-2" height="40px">
                    <v-icon left size="14">
                        fas fa-plus
                    </v-icon>
                    Dodaj osiągnięcie
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row v-if='osiagniecia.length > 0'>
                    <v-col cols="12" md="6" lg="4" xl="3" v-for="item in osiagniecia" :key="item.id" >
                        <v-card outlined class="h-100">
                            <v-card-title primary-title>
                                <v-icon class="mr-2" :class="medalColor(item.miejsce)">fas fa-medal</v-icon> {{item.name}}
                            </v-card-title>
                            <v-card-subtitle>{{item.miejsce}} miejsce - <span v-if="item.type == 0">indywidualne</span><span v-if="item.type == 1">drużynowe</span></v-card-subtitle>
                            <v-card-text>
                                <v-list two-line>
                                      <v-list-item
                                        v-for="uczen in item.uczniowie" :key="uczen.id"
                                        class="px-0"
                                        >
                                        <v-list-item-content>
                                            <v-list-item-title>{{uczen.imie}} {{uczen.nazwisko}}</v-list-item-title>
                                            <v-list-item-subtitle><small>{{uczen.wychowawca}}</small></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                           <v-btn color="red" class="grey--text table-icon" small icon @click="deleteUczen(uczen.id)">
                                                <v-icon size="12">fas fa-trash</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        </v-list-item>
                                </v-list>
                            </v-card-text>
                            <v-card-actions class="pt-0">
                                <v-spacer></v-spacer>
                                <v-btn color="blue" small outlined  @click="editing.osiagniecie = item; editing.display = true;">
                                     <v-icon left size="12">
                                        fas fa-pencil-alt
                                    </v-icon>
                                    Edytuj
                                </v-btn>
                                <v-btn color="blue" small outlined :disabled="item.type == 0 && item.uczniowie.length >= 1" @click="adding.osiagniecie = item; adding.display = true;">
                                     <v-icon left size="12">
                                        fas fa-plus
                                    </v-icon>
                                    Zawodnik
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col class="text-center">
                        Brak danych o osiągnięciach na tych zawodach!
                    </v-col>
                </v-row>

            </v-card-text>
            <createModal :display="creating" :zawody="zawodyId" @created="fetch" @close="creating = false"/>
            <editModal :display="editing.display" :osiagniecie="editing.osiagniecie" @created="fetch" @close="editing.osiagniecie = false; editing.display = false;"/>
            <addUczenModal :display="adding.display"  :osiagniecie="adding.osiagniecie" @created="fetch" @close="adding.osiagniecie = false; adding.display = false;"/>
        </v-card>
    </v-container>
</template>

<script>
import createModal from '@/components/osiagniecia/createModal.vue';
import editModal from '@/components/osiagniecia/editModal.vue';
import addUczenModal from '@/components/osiagniecia/addUczenModal.vue';

export default {
    components:{
        createModal,
        editModal,
        addUczenModal
    },
    data: () => ({
        creating: false,
        editing: {
            display: false,
            osiagniecie: false,
        },
        adding: {
            display: false,
            osiagniecie: false,
        },
        loading: false,
        details: {},
        osiagniecia: {}
    }),
    computed:{
        zawodyId: function(){
            return parseInt(this.$route.params.id)
        }
    },
    methods:{
        fetch: function(){
            this.loading = true;
            this.$http.get('/zawody/getDetails',
                {params:
                    {
                        id: this.$route.params.id
                    }
                })
                .then(res => {
                    this.details = res.data.body['details'];
                    this.osiagniecia = res.data.body['osiagniecia'];
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        deleteUczen: function(id){
            this.$http.post('/zawody/deleteUczen', {
                    id: id
                })
                .catch(err => {
                    console.log(err.response)
                })
                .finally(() => {
                    this.fetch();
                })
        },
        medalColor: function(miejsce){
            if(miejsce == 1) return "yellow--text text--darken-2";
            else if(miejsce == 2) return 'grey--text text--lighten-1';
            else if(miejsce == 3) return 'brown--text';
            else return 'blue--text text--lighten-4';
        }
    },
    created(){
        this.fetch();
    },
    destroyed(){

    }

}
</script>

<style>

</style>