<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
  >
       <v-card>
           <v-card-title primary-title class="letter-spacing">
               Dodawanie osiągnięcia
           </v-card-title>
           <v-card-text class="py-2">
               <v-form v-model="valid">
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0">
                             <v-select
                                :items="dyscypliny"
                                v-model="form.dyscyplina"
                                label="Dyscyplina"
                                outlined
                                dense
                                :error-messages="errors.dyscyplina"
                           ></v-select>
                       </v-col>
                   </v-row>
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0">
                            <v-text-field
                                label="Miejsce"
                                outlined
                                dense
                                v-model.number="form.miejsce"
                                :error-messages="errors.miejsce"
                            ></v-text-field>
                       </v-col>
                   </v-row>
               </v-form>
           </v-card-text>
           <v-card-actions class="pt-0">
               <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="grey"
                        class="text-none letter-spacing"
                        @click="dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="text-none"
                        @click="sendForm"
                    >
                        Dodaj
                    </v-btn>
           </v-card-actions>

       </v-card>
  </v-dialog>
</template>

<script>
import dialog from '@/mixins/dialog.js';

export default {
    mixins: [dialog],
    props:{
        zawody: {
            type: Number,
            required: true,
        }
    },
    data: () => ({
        valid: false,
        menu: false,
        form: {
            dyscyplina: '',
            miejsce: 1,
        },
        errors:{
            dyscyplina: '',
            miejsce: '',
        },
        dyscypliny:[
            {'text': 'Brak dyscypliny', value: -1}
        ]
    }),
    methods:{
        sendForm: function(){
            this.$http.post('/osiagniecia/create', {
                    zawody: this.$props.zawody,
                    dyscyplina: this.form.dyscyplina,
                    miejsce: this.form.miejsce
                })
                .then(() => {
                    this.dialog = false;
                    this.$emit('created');
                })
                .catch(err => {
                    this.errors = err.response.data.body;
                })
        },
        fetchDyscypliny: function(){
             this.$http.get('/dyscypliny/selectList')
                .then(res => {
                    this.dyscypliny = res.data.body;
                })
        },
        clearForm: function(){
            this.form = {
                dyscyplina: '',
                miejsce: 1,
            };
            this.errors = {
                dyscyplina: '',
                miejsce: '',
            };
        }
    },
    created(){
        this.fetchDyscypliny();
    }
}
</script>

<style>

</style>